import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import ScoreForm from "./ScoreForm";

const calcScoreBgColor = (score) => {
  if (score === "N/A") {
    return "hsl(193,100%,40%)";
  }
  const h = (score - 1) * 12;
  const s = 100;
  const l = 50;
  return `hsl(${h},${s}%,${l}%)`;
};

const ManagerRef = ({ id, name, mode }) => {
  const inputRef = useRef(null);
  const scoreFromDb = useSelector(state => state.scores.managersTotal[id]);
  const totalVotes = useSelector((state) => state.scores.totalVotes);
  const [focus, setFocus] = useState(false);
  const handleClick = (e) => {
    setFocus(true);
    inputRef.current.focus();
  };
  const handleScoreFocus = () => {
    setFocus(false);
  };
  
  if(mode === "review"){
    const scoreBg = calcScoreBgColor(
      totalVotes ? scoreFromDb / totalVotes : "N/A"
    );
    return (<div
      className="manager-item"
      style={{ cursor: mode === "review" ? "auto" : "pointer" }}
      onClick={handleClick}
    >
      <div className="manager-item-name">{name}</div>

    <div
      className="manager-score-from-db"
      style={{
        backgroundColor: scoreBg,
      }}
    >
      {totalVotes ? (scoreFromDb / totalVotes).toFixed(1) : "N/A"}
    </div>

    </div>)
  }
  return (
    <div
      className="manager-item"
      style={{ cursor: mode === "review" ? "auto" : "pointer" }}
      onClick={handleClick}
    >
      <div className="manager-item-name">{name}</div>
      <ScoreForm
        inputRef={inputRef}
        canBeVoted={mode === "review" ? false : true}
        focusCb={handleScoreFocus}
        id={id}
        isPlayer={false}
      />
    </div>
  );
};

export default ManagerRef;
